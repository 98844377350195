import Layout from "./layouts/Layout";
import NavBar from "./layouts/main/NavBar";
import Home from "./pages/Home";
import About from "./pages/About";
import Skills from "./pages/Skills";
import Experience from "./pages/Experience";
import Work from "./pages/Work";
import Contact from "./pages/Contact";
import { useState } from "react";
import Toggle from "./components/Toggle";
import DarkModeToggle from "./components/DarkModeToggle";

function App() {
  return (
    <Layout>
      {/* <div className="h-screen transition-colors duration-300 bg-gray-100 dark:bg-gray-900 text-black dark:text-white">
        <DarkModeToggle />
        <h1 className="text-3xl font-bold text-center mt-10">
          Dynamic Background Color
        </h1>
        <p className="text-center mt-4">Switch between light and dark modes!</p>
      </div> */}
      <NavBar />
      <Home />
      <About />
      <Skills />
      <Experience />
      <Work />
      <Contact />
    </Layout>
  );
}

export default App;
