import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

interface TypingTextProps {
  text: string;
  typingSpeed?: number;
  delay?: number; // New prop for delay in milliseconds
}

const TypingText: React.FC<TypingTextProps> = ({
  text,
  typingSpeed = 50,
  delay = 10,
}) => {
  const [displayedText, setDisplayedText] = useState("");
  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
    let typingInterval: NodeJS.Timeout | null = null;
    let cursorInterval: NodeJS.Timeout | null = null;

    // Delay typing start
    timeoutId = setTimeout(() => {
      typingInterval = setInterval(() => {
        if (displayedText.length < text.length) {
          setDisplayedText((prev) => text.slice(0, prev.length + 1));
        } else {
          clearInterval(typingInterval as NodeJS.Timeout);
          timeoutId = setTimeout(() => {
            setShowCursor(false);
          }, 500); // Adjust timing if needed
        }
      }, typingSpeed);

      cursorInterval = setInterval(() => {
        setShowCursor((prev) => !prev);
      }, 500); // Adjust blinking speed here
    }, delay);

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      if (typingInterval) clearInterval(typingInterval);
      if (cursorInterval) clearInterval(cursorInterval);
    };
  }, [text, typingSpeed, displayedText, delay]);

  return (
    <div style={{ display: "inline-block", position: "relative" }}>
      <motion.span animate={{ opacity: [0, 1] }} transition={{ duration: 0.5 }}>
        {displayedText}
      </motion.span>
    </div>
  );
};

export default TypingText;
