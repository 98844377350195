import React, { useEffect, useState } from "react";
import data from "../data/projects.json";
import { motion } from "framer-motion";
import worksData from "../data/work.json";
import { AiFillEye, AiFillGithub } from "react-icons/ai";

interface Props {}

interface IWork {
  type: string;
  title: string;
  description: string;
  tags: string[];
  imgUrl: string;
  projectLink: string;
  codeLink: string;
}
const Work: React.FC<Props> = () => {
  // projects file
  const [activeFilter, setActiveFilter] = useState("All");
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const [works, setWorks] = useState<IWork[]>([]);
  const [filterWork, setFilterWork] = useState<IWork[]>([]);

  // Fetch works data from local JSON file
  useEffect(() => {
    // Simulate fetching data (replace with actual logic for async fetching)
    setWorks(worksData);
    setFilterWork(worksData);
  }, []);

  // handle work filter
  const handleWorkFilter = (item: string) => {
    setActiveFilter(item);
    setAnimateCard({ y: 100, opacity: 0 });

    setTimeout(() => {
      setAnimateCard({ y: 0, opacity: 1 });

      if (item === "All") {
        setFilterWork(worksData);
      } else {
        setFilterWork(worksData.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };
  //setProject(data);

  return (
    <div
      id="work"
      className="w-full min-h-screen text-text_navbar bg-background"
    >
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pt-[80px]">
          <p className="text-4xl font-bold inline border-b-4 border-border_highlighter">
            Work
          </p>
          <p className="py-6">// Check out some of my recent work</p>
        </div>

        <div className="flex flex-wrap justify-end items-center my-4 gap-2">
          {["MERN", "Websites", "Others", "All"].map((item, index) => (
            <div
              key={index}
              onClick={() => handleWorkFilter(item)}
              className={`px-4 py-2 cursor-pointer font-bold transition duration-300 border-2 border-border_highlighter ${
                activeFilter === item
                  ? "bg-border_highlighter text-background"
                  : "text-text_navbar"
              } hover:bg-border_highlighter hover:text-background`}
            >
              {item}
            </div>
          ))}
        </div>
        {/* container for projects */}
        <motion.div
          animate={animateCard}
          transition={{ duration: 0.5, delayChildren: 0.5 }}
          className="grid sm:grid-cols-2 md:grid-cols-3 gap-4"
        >
          {/* Gird Item */}
          {filterWork.map((item: IWork, index: number) => (
            <div
              key={index}
              className="flex flex-col max-w-sm w-fit rounded-lg overflow-hidden shadow-lg shadow-shadow group container"
            >
              <div
                key={index}
                style={{ backgroundImage: `url(${item.imgUrl})` }}
                className="
                 w-[270px] h-[193px]
              flex justify-center text-center items-center mx-auto content-div bg-cover bg-no-repeat bg-center"
              >
                {/* Hover effect for images */}
                <motion.div
                  initial={{ opacity: 0 }}
                  whileHover={{ opacity: 1 }}
                  transition={{
                    duration: 0.25,
                    ease: "easeInOut",
                    staggerChildren: 0.5,
                  }}
                  className="opacity-0 w-full h-full my-auto flex items-center justify-center flex-col bg-gray-300 rounded-md bg-clip-padding backdrop-filter backdrop-blur-lg bg-opacity-20 group-hover:opacity-100 "
                >
                  <span className="text-2xl font bold text-background tracking-wider ">
                    {item?.title}
                  </span>
                  <div className="pt-8 text-center flex items-center justify-center">
                    {/* eslint-disable-next-line */}
                    <a
                      href={item?.projectLink}
                      target="_blank"
                      rel="noreferrer"
                      className="p-2 rounded-full bg-background bg-opacity-50 text-text_navbar mx-2"
                    >
                      <motion.div
                        whileInView={{ scale: [0, 1] }}
                        whileHover={{ scale: [1, 0.9] }}
                        transition={{ duration: 0.25 }}
                        className="flex items-center justify-center"
                      >
                        <AiFillEye />
                      </motion.div>
                    </a>
                    {/* View work code link */}
                    <a
                      href={item?.codeLink}
                      target="_blank"
                      rel="noreferrer"
                      className="p-2 rounded-full bg-background bg-opacity-50 text-text_navbar mx-2"
                    >
                      <motion.div
                        whileInView={{ scale: [0, 1] }}
                        whileHover={{ scale: [1, 0.9] }}
                        transition={{ duration: 0.25 }}
                        className="flex items-center justify-center"
                      >
                        <AiFillGithub />
                      </motion.div>
                    </a>
                  </div>
                </motion.div>
              </div>

              <div className="p-4 max-w-[270px] flex flex-col items-start gap-2">
                <div className="w-full flex flex-row items-center justify-between flex-wrap gap-1">
                  <h4 className="font-bold">{item?.title}</h4>
                  <div className="flex items-center justify-center">
                    {/* eslint-disable-next-line */}
                    <a
                      href={item?.projectLink}
                      target="_blank"
                      rel="noreferrer"
                      className="p-2 rounded-full bg-text_secondary bg-opacity-50 text-background mx-2"
                    >
                      <motion.div
                        whileInView={{ scale: [0, 1] }}
                        whileHover={{ scale: [1, 0.9] }}
                        transition={{ duration: 0.25 }}
                        className="flex items-center justify-center"
                      >
                        <AiFillEye />
                      </motion.div>
                    </a>
                    {/* View work code link */}
                    {item?.codeLink?.length <= 0 ? (
                      ""
                    ) : (
                      <a
                        href={item?.codeLink}
                        target="_blank"
                        rel="noreferrer"
                        className="p-2 rounded-full bg-text_secondary bg-opacity-50 text-background mx-2"
                      >
                        <motion.div
                          whileInView={{ scale: [0, 1] }}
                          whileHover={{ scale: [1, 0.9] }}
                          transition={{ duration: 0.25 }}
                          className="flex items-center justify-center"
                        >
                          <AiFillGithub />
                        </motion.div>
                      </a>
                    )}
                  </div>
                </div>

                <p className="text-sm mb-4">{item?.description}</p>

                {/* work tags */}
                <div className="flex gap-1 flex-wrap">
                  {item?.tags?.map((tag: string, index: number) => (
                    <>
                      {tag !== "Others" && (
                        <p
                          key={index}
                          className="px-1 py-0.5 text-xs font-bold rounded-full bg-text_secondary text-background"
                        >
                          {tag !== "Others" && tag}
                        </p>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Work;
