import { useRef, useState, useEffect } from "react";
import { RouteItem, routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import { motion, useInView } from "framer-motion";
import CloseIcon from "../../assets/icons/CloseIcon";
import MenuIcon from "../../assets/icons/MenuIcon";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
interface Props {}

const NavBar: React.FC<Props> = () => {
  const [showNav, setShowNav] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  // Function to handle scroll event and update activeSection state accordingly
  const handleScroll = () => {
    // Logic to determine which section is currently active based on scroll position
    // For simplicity, assuming each section has an ID corresponding to its navigation item
    const scrollPosition = window.scrollY;

    // Example logic: Replace with your own logic based on your section layout
    const sections = ["home", "about", "skills", "exp", "work", "contact"];
    for (let i = sections.length - 1; i >= 0; i--) {
      const section = document.getElementById(sections[i]);
      if (section && section.offsetTop <= scrollPosition + 50) {
        setActiveSection(sections[i]);
        break;
      }
    }
  };
  const handleNav = () => {
    setShowNav(!showNav);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <nav className="fixed w-full h-[80px] flex items-center justify-between px-4 bg-background text-text_navbar z-20">
      <div>
        <img src={"./logo.png"} alt="logo" style={{ width: "50px" }} />
      </div>

      {/* Menu */}
      <ul className="hidden md:flex">
        <li
          className={
            activeSection === "home"
              ? "px-4 cursor-pointer border-b-2 border-b-border_highlighter"
              : "px-4 cursor-pointer border-b-2 border-b-background hover:border-b-border_highlighter"
          }
          onClick={() => scrollToSection("home")}
        >
          Home
        </li>
        <li
          className={
            activeSection === "about"
              ? "px-4 cursor-pointer border-b-2 border-b-border_highlighter"
              : "px-4 cursor-pointer border-b-2 border-b-background hover:border-b-border_highlighter"
          }
          onClick={() => scrollToSection("about")}
        >
          About
        </li>
        <li
          className={
            activeSection === "skills"
              ? "px-4 cursor-pointer border-b-2 border-b-border_highlighter"
              : "px-4 cursor-pointer border-b-2 border-b-background hover:border-b-border_highlighter"
          }
          onClick={() => scrollToSection("skills")}
        >
          Skills
        </li>
        <li
          className={
            activeSection === "exp"
              ? "px-4 cursor-pointer border-b-2 border-b-border_highlighter"
              : "px-4 cursor-pointer border-b-2 border-b-background hover:border-b-border_highlighter"
          }
          onClick={() => scrollToSection("exp")}
        >
          Experience
        </li>
        <li
          className={
            activeSection === "work"
              ? "px-4 cursor-pointer border-b-2 border-b-border_highlighter"
              : "px-4 cursor-pointer border-b-2 border-b-background hover:border-b-border_highlighter"
          }
          onClick={() => scrollToSection("work")}
        >
          Work
        </li>
        <li
          className={
            activeSection === "contact"
              ? "px-4 cursor-pointer border-b-2 border-b-border_highlighter"
              : "px-4 cursor-pointer border-b-2 border-b-background hover:border-b-border_highlighter"
          }
          onClick={() => scrollToSection("contact")}
        >
          Contact
        </li>
      </ul>

      {/* Hamburger Menu */}
      <div
        className="md:hidden z-10 cursor-pointer"
        onClick={() => {
          handleNav();
        }}
      >
        {showNav ? <CloseIcon /> : <MenuIcon />}
      </div>

      {/* Mobile Menu */}
      <motion.ul
        initial={{ x: 200, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.2 }}
        className={
          !showNav
            ? "hidden"
            : "absolute top-0 left-0 w-full h-screen flex flex-col justify-center gap-4 items-center  md:hidden bg-text_primary rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-50 "
        }
      >
        <motion.li
          initial={{ scale: 0 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.05, delay: 0.2 }}
          className="px-4 text-4xl cursor-pointer"
          onClick={() => {
            handleNav();
            scrollToSection("home");
          }}
        >
          Home
        </motion.li>
        <motion.li
          initial={{ scale: 0 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.05, delay: 0.25 }}
          className="px-4 text-4xl cursor-pointer"
          onClick={() => {
            handleNav();
            scrollToSection("about");
          }}
        >
          About
        </motion.li>
        <motion.li
          initial={{ scale: 0 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.05, delay: 0.3 }}
          className="px-4 text-4xl cursor-pointer"
          onClick={() => {
            handleNav();
            scrollToSection("skills");
          }}
        >
          Skills
        </motion.li>
        <motion.li
          initial={{ scale: 0 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.05, delay: 0.35 }}
          className="px-4 text-4xl cursor-pointer"
          onClick={() => {
            handleNav();
            scrollToSection("exp");
          }}
        >
          Experience
        </motion.li>
        <motion.li
          initial={{ scale: 0 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.05, delay: 0.4 }}
          className="px-4 text-4xl cursor-pointer"
          onClick={() => {
            handleNav();
            scrollToSection("work");
          }}
        >
          Work
        </motion.li>
        <motion.li
          initial={{ scale: 0 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.05, delay: 0.45 }}
          className="px-4 text-4xl cursor-pointer"
          onClick={() => {
            handleNav();
            scrollToSection("contact");
          }}
        >
          Contact
        </motion.li>
      </motion.ul>

      {/* Social Icons */}
      <div className="fixed hidden lg:flex flex-col top-[35%] left-0 ">
        <ul>
          <li className="w-[150px] h-[60px] flex items-center justify-between ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600">
            <a
              className="flex justify-around items-center w-full text-text_navbar"
              href="https://www.linkedin.com/in/hridayesh-padalkar"
              target="_blank"
            >
              <>&nbsp;&nbsp;LinkedIn</>
              <FaLinkedin size={30} />
            </a>
          </li>
          <li className="w-[150px] h-[60px] flex items-center justify-between ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333] ">
            <a
              className="flex justify-around items-center w-full text-text_navbar"
              href="https://github.com/Hridayesh12"
              target="_blank"
            >
              <>&nbsp;&nbsp;Github</>
              <>
                &nbsp;&nbsp;
                <FaGithub size={30} />
              </>
            </a>
          </li>
          <li className="w-[150px] h-[60px] flex items-center justify-between ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]">
            <a
              className="flex justify-around items-center w-full text-text_navbar"
              href="mailto:hridayeshpadalkar7@gmail.com"
              target="_blank"
            >
              <>&nbsp;&nbsp;Email</>
              <>
                &nbsp;&nbsp; &nbsp;&nbsp;
                <HiOutlineMail size={30} />
              </>
            </a>
          </li>
          <li className="w-[150px] h-[60px] flex items-center justify-between ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]  ">
            <a
              className="flex justify-around items-center w-full text-text_navbar"
              href="/"
              target="_blank"
            >
              <>&nbsp;&nbsp;Resume</>
              <BsFillPersonLinesFill size={30} />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;

//  <ul className="absolute z-10 w-screen h-screen flex flex-row items-center justify-around">
//    {routes?.map((menu: RouteItem, index: number) => (
//      <>
//        {menu?.key !== "router-error" && (
//          <motion.button
//            initial={{ opacity: 0, y: -500 }} // Initial state (hidden above)
//            whileInView={{ opacity: 1, y: 0 }}
//            transition={{ duration: 0.5, delay: 0.1 * index }} // Transition duration
//            className={
//              index === 1
//                ? "bg-[#17223b] h-full w-full"
//                : index === 2
//                ? "bg-[#8d0240] h-full w-full"
//                : index === 3
//                ? "bg-[#1045a8] h-full w-full"
//                : index === 4
//                ? "bg-[#1b2021] h-full w-full"
//                : "h-full w-full"
//            }
//            onClick={(e) => {
//              e.preventDefault();
//              console.log(menu?.path);
//              navigate(menu?.path);
//            }}
//          >
//            {menu?.title}
//          </motion.button>
//        )}
//      </>
//    ))}
//  </ul>;
