import React from "react";
import data from "../data/experience.json";
interface Props {}

interface IExperience {
  id: number;
  company: string;
  title: string;
  start: string;
  end: string;
  description: string;
}

const Experience: React.FC<Props> = () => {
  const exp: Array<IExperience> = data;
  return (
    <div
      id="exp"
      className="w-full min-h-screen bg-background text-text_navbar flex justify-center items-center"
    >
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pt-[80px]">
          <p className="text-4xl font-bold inline border-b-4 border-border_highlighter">
            Experience
          </p>
          <p className="py-6">// Relevant Experience</p>
        </div>

        <div className="-my-6 flex flex-col items-center justify-center">
          {exp.map((item: IExperience, index: number) => (
            <div className="relative pl-8 sm:pl-32 py-6 group">
              <div className="font-caveat font-medium text-2xl text-text_highlighter mb-1 sm:mb-0">
                {item?.company}
              </div>

              <div className="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-border_highlighter after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
                <time className="sm:absolute -left-1 -translate-y-0.5 flex flex-col items-center justify-center text-xs font-semibold uppercase w-24 h-8 mb-0 sm:mb-0 text-background bg-text_highlighter rounded-full p-2">
                  <>{item?.start}</> - <>{item?.end}</>
                </time>
                <div className="text-xl font-bold text-text_heading">
                  {item?.title}
                </div>
              </div>

              <div className="text-text_secondary max-w-[300px] text-justify">
                {item?.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Experience;
