import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

interface Props {}

const Contact: React.FC<Props> = () => {
  const form = useRef<HTMLFormElement>(null);
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({
    user_name: "",
    user_email: "",
    message: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear error message when user starts typing again
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const sendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Simple validation example: ensure all fields are filled
    if (!formData.user_name || !formData.user_email || !formData.message) {
      setFormErrors({
        user_name: formData.user_name ? "" : "Please enter your name",
        user_email: formData.user_email ? "" : "Please enter your email",
        message: formData.message ? "" : "Please enter your message",
      });
      return;
    }

    try {
      if (form.current) {
        const result = await emailjs.sendForm(
          "portfolio_contact",
          "template_vzus28a",
          form.current,
          {
            publicKey: "DXXW3NjMO-5AqJa9Q",
          }
        );

        if (result.text === "OK") {
          Swal.fire({
            icon: "success",
            title: "Message Sent",
            showConfirmButton: false,
            timer: 1500,
          });
          // Reset form fields after successful submission
          setFormData({
            user_name: "",
            user_email: "",
            message: "",
          });
        } else {
          throw new Error("Failed to send message");
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops! Unable to send message",
        showConfirmButton: false,
        timer: 1500,
      });
      console.error("Error sending email:", error);
    }
  };

  return (
    <div
      id="contact"
      className="w-full min-h-screen bg-background flex justify-center items-center p-4 text-text_navbar"
    >
      <form
        ref={form}
        onSubmit={sendEmail}
        className="flex flex-col max-w-[600px] w-full gap-2"
      >
        <div className="pt-[80px]">
          <p className="text-4xl font-bold inline border-b-4 border-border_highlighter ">
            Contact
          </p>
          <p className=" py-4">
            Submit the form below or shoot me an email -
            hridayeshpadalkar7@gmail.com
          </p>
        </div>
        <input
          className="bg-text_heading p-2 text-background"
          type="text"
          placeholder="Your Name"
          name="user_name"
          value={formData.user_name}
          onChange={handleInputChange}
        />
        {formErrors.user_name && (
          <p className="text-red-500">{formErrors.user_name}</p>
        )}
        <input
          type="email"
          className="bg-text_heading p-2 text-background"
          placeholder="Your Email"
          name="user_email"
          value={formData.user_email}
          onChange={handleInputChange}
        />
        {formErrors.user_email && (
          <p className="text-red-500">{formErrors.user_email}</p>
        )}
        <textarea
          className="bg-text_heading p-2 text-background"
          name="message"
          placeholder="Message"
          value={formData.message}
          onChange={handleInputChange}
        />
        {formErrors.message && (
          <p className="text-red-500">{formErrors.message}</p>
        )}
        <input
          type="submit"
          value="Let's Collaborate"
          className="text-text_navbar border-2 hover:text-background hover:bg-text_highlighter hover:border-border_highlighter px-4 py-3 my-8 mx-auto flex items-center"
        />
      </form>
    </div>
  );
};

export default Contact;

{
  /* <form method="POST" action="https://getform.io/f/adryydwa">
        <input name="name" />
        <input
          className="my-4 p-2 bg-text_heading text-background"
          type="email"
          placeholder="Email"
          name="email"
        />
        <textarea></textarea>
        <button></button>
      </form> */
}
