import React from "react";
import { motion } from "framer-motion";
import { HiArrowNarrowRight } from "react-icons/hi";
import TypingText from "../components/TypingText";
import WordAppearEffect from "../components/WordAppear";
interface Props {}

const Home: React.FC<Props> = () => {
  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div id="home" className="w-full min-h-screen h-screen bg-background">
      {/* Home */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <motion.p
          initial={{ x: -200, opacity: 0 }} // Start position outside the viewport
          whileInView={{ x: 0, opacity: 1 }} // Move in from left and fade in
          exit={{ x: 200, opacity: 0 }} // Move out to the right and fade out
          transition={{ duration: 0.5 }}
          style={{ whiteSpace: "pre-wrap" }}
          className="text-text_highlighter pt-[80px]"
        >
          Hi, my name is
        </motion.p>
        <h1 className="text-4xl sm:text-7xl font-bold text-text_heading">
          <WordAppearEffect text="Hridayesh" />
        </h1>
        <h2 className="text-4xl sm:text-7xl font-bold text-text_secondary">
          <TypingText text="I'm a Software Developer" />
        </h2>
        <motion.p
          initial={{ x: -200, opacity: 0 }} // Start position outside the viewport
          whileInView={{ x: 0, opacity: 1 }} // Move in from left and fade in
          exit={{ x: 200, opacity: 0 }} // Move out to the right and fade out
          transition={{ duration: 0.5 }}
          style={{ whiteSpace: "pre-wrap" }}
          className="text-text_secondary py-4 max-w-[700px] text-justify"
        >
          I'm a software developer specializing in building amazing web
          experiences. Crafting seamless interactions that users love is where I
          thrive. I'm passionate about leveraging technology to create intuitive
          and innovative solutions.
        </motion.p>
        <div>
          <button
            className="text-text_navbar group border-2 px-6 py-3 my-2 flex items-center hover:text-background hover:bg-text_highlighter hover:border-border_highlighter"
            onClick={() => {
              scrollToSection("work");
            }}
          >
            View Work{" "}
            <span className="group-hover:rotate-90 duration-300">
              <HiArrowNarrowRight className="ml-3 hover:" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
