import React from "react";
import { motion } from "framer-motion";
interface Props {}

const About: React.FC<Props> = () => {
  return (
    <div
      id="about"
      className="w-full min-h-screen bg-background text-text_navbar flex justify-center items-center"
    >
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pl-4">
            <motion.p
              initial={{ scale: 0 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.5 }}
              className="text-4xl font-bold inline border-b-4 border-border_highlighter"
            >
              About
            </motion.p>
          </div>
          <div></div>
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
          <div className="sm:text-right text-4xl font-bold overflow-hidden">
            <motion.p
              initial={{ opacity: 0, x: 200 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              Hi. I'm Hridayesh, nice to meet you. Please take a look around.
            </motion.p>
          </div>
          <div className="overflow-hidden">
            <motion.p
              initial={{ opacity: 0, x: -200 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="text-justify"
            >
              I am passionate about building excellent software that improves
              the lives of those around me. I specialize in creating software
              for clients ranging from individuals and small-businesses all the
              way to large enterprise corporations. What would you do if you had
              a software expert available at your fingertips?
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
