import React from "react";

interface Props {}

const Skills: React.FC<Props> = () => {
  return (
    <div
      id="skills"
      className="w-full min-h-screen bg-backkground text-text_navbar  flex justify-center items-center"
    >
      {/* Container */}
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pt-[80px]">
          <p className="text-4xl font-bold inline border-b-4 border-border_highlighter">
            Skills
          </p>
          <p className="py-4">// These are the technologies I've worked with</p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8">
          <div className="shadow-md shadow-shadow hover:scale-110 duration-500">
            <img
              className="w-12 mx-auto"
              src="./assets/icons/java-logo.png"
              alt="HTML icon"
            />
            <p className="my-4">JAVA</p>
          </div>
          <div className="shadow-md shadow-shadow hover:scale-110 duration-500">
            <img
              className="w-12 mx-auto"
              src="./assets/icons/html.png"
              alt="HTML icon"
            />
            <p className="my-4">HTML</p>
          </div>
          <div className="shadow-md shadow-shadow hover:scale-110 duration-500">
            <img
              className="w-12 mx-auto"
              src="./assets/icons/css.png"
              alt="HTML icon"
            />
            <p className="my-4">CSS</p>
          </div>
          <div className="shadow-md shadow-shadow hover:scale-110 duration-500">
            <img
              className="w-12 mx-auto"
              src="./assets/icons/javascript.png"
              alt="HTML icon"
            />
            <p className="my-4">JAVASCRIPT</p>
          </div>
          <div className="shadow-md shadow-shadow hover:scale-110 duration-500">
            <img
              className="w-12 mx-auto"
              src="./assets/icons/typescript.png"
              alt="HTML icon"
            />
            <p className="my-4">TYPESCRIPT</p>
          </div>
          <div className="shadow-md shadow-shadow hover:scale-110 duration-500">
            <img
              className="w-12 mx-auto"
              src="./assets/icons/react.png"
              alt="HTML icon"
            />
            <p className="my-4">REACT</p>
          </div>
          <div className="shadow-md shadow-shadow hover:scale-110 duration-500">
            <img
              className="w-12 mx-auto"
              src="./assets/icons/github.png"
              alt="HTML icon"
            />
            <p className="my-4">GITHUB</p>
          </div>
          <div className="shadow-md shadow-shadow hover:scale-110 duration-500">
            <img
              className="w-12 mx-auto"
              src="./assets/icons/node.png"
              alt="HTML icon"
            />
            <p className="my-4">NODE JS</p>
          </div>
          <div className="shadow-md shadow-shadow hover:scale-110 duration-500">
            <img
              className="w-12 mx-auto"
              src="./assets/icons/mongo.png"
              alt="HTML icon"
            />
            <p className="my-4">MONGO DB</p>
          </div>
          <div className="shadow-md shadow-shadow hover:scale-110 duration-500">
            <img
              className="w-12 mx-auto"
              src="./assets/icons/tailwind.png"
              alt="HTML icon"
            />
            <p className="my-4">TAILWIND</p>
          </div>
          <div className="shadow-md shadow-shadow hover:scale-110 duration-500">
            <img
              className="w-12 mx-auto"
              src="./assets/icons/mui.png"
              alt="HTML icon"
            />
            <p className="my-4">MATERIAL UI</p>
          </div>
          <div className="shadow-md shadow-shadow hover:scale-110 duration-500">
            <img
              className="w-12 mx-auto"
              src="./assets/icons/docker.png"
              alt="HTML icon"
            />
            <p className="my-4">DOCKER</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
