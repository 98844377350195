import { ReactNode } from "react";
import NavBar from "./main/NavBar";

interface Props {
  children: ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
  return <div className="bg-background">{children}</div>;
};

export default Layout;
