import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

interface WordAppearEffectProps {
  text: string;
  typingSpeed?: number;
}

const WordAppearEffect: React.FC<WordAppearEffectProps> = ({
  text,
  typingSpeed = 250,
}) => {
  const [characters, setCharacters] = useState<string[]>([]);

  useEffect(() => {
    let timeoutIds: NodeJS.Timeout[] = [];

    // Clear timeouts when component unmounts or text changes
    return () => {
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, [text]);

  useEffect(() => {
    let timeoutIds: NodeJS.Timeout[] = [];
    const charsWithSpaces: string[] = [];

    for (let i = 0; i < text.length; i++) {
      const char = text[i];
      timeoutIds.push(
        setTimeout(() => {
          charsWithSpaces.push(char);
          setCharacters([...charsWithSpaces]);
        }, i * typingSpeed)
      );
    }

    // Clear timeouts when component unmounts or text changes
    return () => {
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, [text, typingSpeed]);

  return (
    <div style={{ display: "inline-block", position: "relative" }}>
      {characters.map((char, index) => (
        <motion.span
          key={index}
          style={{ display: "inline-block", overflow: "hidden" }}
          animate={{ y: [20, 0], opacity: [0, 1] }}
          transition={{ duration: 0.5 }}
        >
          {char === " " ? "\u00A0" : char}{" "}
          {/* Replace space with non-breaking space */}
        </motion.span>
      ))}
    </div>
  );
};

export default WordAppearEffect;
